import { HistoryLocation } from "@reach/router";
import { graphql, useStaticQuery } from "gatsby";
import React from "react";
// @ts-ignore
import Obfuscate from 'react-obfuscate';
import { Col, Container, Image, Row } from "react-bootstrap";
import Seo from "../components/Seo";
import TextPageLayout from "../components/TextPageLayout";

interface Props {
  location: HistoryLocation;
}

interface AvatarProps {
  name: string,
  img: string,
  role: string,
  email: string
}

const Contact = ({ location }: Props) => {

  const data: any = useStaticQuery(graphql`
  query ContactQuery {
    markdownRemark(frontmatter: {slug: {eq: "/contact/"}}) {
      html
      frontmatter {
        slug
        title
        avatars {
          name
          img
          role
          email
        }
      }
    }
  }
  `);

  const { markdownRemark } = data; // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark;
  const { title, avatars } = frontmatter;

  const avat = avatars ? avatars.map(({ name, img, role, email }: AvatarProps) => Avatar(name, img, role, email)) : [];

  return (
    <TextPageLayout title={title} location={location}>
      <Seo title={title} />
      <div className={`blog-post-content`} dangerouslySetInnerHTML={{ __html: html }} />
      <Container>
        <Row>
          {avat}
        </Row>
      </Container>
    </TextPageLayout>
  )
}


const Avatar = (name: string, image: string, role: string, email: string) => {

  return (
    <Col>
      <div className="contact-avatar">
        <Image fluid={true} src={`/images/contact/${image}`} />
        <h4>{name}</h4>
        <p className="role">{role}</p>
        <Obfuscate
          email={email}
        />
      </div>
    </Col>
  );
}

export default Contact;
