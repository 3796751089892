import { HistoryLocation } from "@reach/router";
import React from 'react';
import { Col, Container, Row } from "react-bootstrap";
import Layout from './layout';

interface TextPageLayoutProps {
  location: HistoryLocation;
  title: string;
  children: React.ReactNode;
}

const TextPageLayout = ({ title, location, children }: TextPageLayoutProps) => {

  return (
    <Layout location={location}>
      <Container fluid={true} className="leafs">
        <Container>
        <Row className="bg-cta justify-content-md-center">
            <Col xl="8" lg="10" sm="12" className="dimmed">
              <div>
                <h1 dangerouslySetInnerHTML={{ __html: title }}>
                </h1>
              </div>
            </Col>
        </Row>
        </Container>
      </Container>
      <Container>
        <Row className="justify-content-md-center text-start">
          <Col xl="8" lg="10" sm="12" className="text-page-layout-parent">
            {children}
          </Col>
        </Row>
      </Container>
    </Layout>
  )
};

export default TextPageLayout;
